import axios from "axios"
import firebase from "firebase"
import { zendesk } from "services/zendesk"
import { generateConsultFirebaseDocument, generateZendeskRequestTemplate } from "./generateTemplates"

import { convertBooleanToString } from "services/general"
import { computeKesslerScore } from "./computeKesslerScore"

import {
  GATSBY_REQUEST_OBJECT_FIELD_ID,
  GATSBY_MOBILE_NUMBER_FIELD_ID,
  GATSBY_PREFERRED_SCHEDULE_FIELD_ID,
  GATSBY_KESSLER_SCORE_FIELD_ID,
  GATSBY_WEBSITE_URL,
  GATSBY_ENV,
  GATSBY_MAKE_CREATE_NEW_REQUEST_WEBHOOK_URL,
} from "gatsby-env-variables"

export const handleSubmitBooking = async ({
  mentalHealthState,
  callback,
  errorCallback,
  module,
  userData,
  addresses,
}) => {
  try {
    let bookingRequestTemplate = generateZendeskRequestTemplate({
      mentalHealthState,
      userData,
      addresses,
    })

    let zendeskResponse = await zendesk({ module }).post(
      "/requests",
      bookingRequestTemplate
    )

    const {
      kesslerDepressed,
      kesslerEffort,
      kesslerHopeless,
      kesslerNervous,
      kesslerRestless,
      kesslerWorthless,
    } = mentalHealthState

    const kessler6 = {
      kesslerDepressed,
      kesslerEffort,
      kesslerHopeless,
      kesslerNervous,
      kesslerRestless,
      kesslerWorthless,
    }

    let document = generateConsultFirebaseDocument({
      requestData: mentalHealthState,
      zendeskId: zendeskResponse?.data?.request?.id,
      userData: userData,
    })

    const newConsult = await firebase
      .firestore()
      .collection("users")
      .doc(userData?.id)
      .collection("consultations")
      .add(document)

    await axios.post(GATSBY_MAKE_CREATE_NEW_REQUEST_WEBHOOK_URL, {
      userData,
      requestData: convertBooleanToString({ data: document }),
      type: "selfRequest",
      zendeskId: zendeskResponse?.data?.request?.id,
      consultId: newConsult.id,
      kessler: computeKesslerScore(kessler6),
      website: GATSBY_WEBSITE_URL,
      environment: GATSBY_ENV === "production" ? "production" : "develop",
    })

    if (callback) callback()
  } catch (error) {
    console.log({ error })
    if (errorCallback) errorCallback(error)
  }
}
