export const hasNoAnswer = (state, fields) => {
  let fieldNames = fields
    .filter((field) => field.required)
    .map((field) => field.name)

  let noValues = []

  for (let name of fieldNames) {
    if (!state[name]) noValues.push(state[name])
  }

  return noValues.length > 0
}
