export const computeKesslerScore = (kessler6) => {
  let answers = Object.values(kessler6)

  const getScore = (answer) => {
    switch (answer) {
      case "Always":
        return 0
      case "Often":
        return 1
      case "Sometimes":
        return 2
      case "Rarely":
        return 3
      case "Never":
        return 4
      default:
        return null
    }
  }

  let score = answers.reduce(
    (accumulator, currentValue) => accumulator + getScore(currentValue),
    0
  )
  return score
}
