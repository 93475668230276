import { get } from "lodash"
import { navigate } from "gatsby"
import classNames from "classnames"
import React, { useContext, useState, useEffect } from "react"

import Layout from "components/Layout/Layout"
import Container from "components/Layout/Container"
import Section from "components/Elements/Section"

import EditDetailsButton from "elements/EditDetailsButton"
import SummaryConsent from "./SummaryConsent"

import styles from "./utils/bookingSummary.module.scss"
import { handleSubmitBooking } from "../services/handleSubmitBooking"
import { useMentalHealthFormFields } from "../hooks/useMentalHealthFormFields"
import { MentalHealthContext } from "../MentalHealthContext/MentalHealthContext"

import { getSignedInUser } from "../../Auth/services/user"
import PersonalDetails from "./PersonalDetails"
import { hasNoAnswer } from "./utils/hasNoAnswer"
import { AppContext } from "../../../context/AppContext"
import { isBrowser } from "../../../services/general"

let { parseFormField } = require("services/airtable")

const BookingSummary = ({ pageContext }) => {
  const userData = getSignedInUser()?.userData
  const addresses = getSignedInUser()?.addresses

  const { dispatch } = useContext(AppContext)
  const { mentalHealthState, mentalHealthDispatch } = useContext(
    MentalHealthContext
  )
  const [loading, setLoading] = useState(false)
  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: pageContext.formFields,
  })
  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.summarySection,
    }))
  )
  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  useEffect(() => {
    if (isBrowser()) {
      mentalHealthDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    }
  }, [mentalHealthDispatch])

  // retain field values when refreshing the summary page
  useEffect(()=>{
    if(isBrowser()){
      mentalHealthDispatch({ 
        type: "SAVE_CONTEXT_TO_SESSION",
        payload: { ...mentalHealthState }
       })
    }
  }, [])

  const handleSuccessCallback = () => {
    dispatch({ type: "SET_SELF_REQUEST_COMPLETE" })
    mentalHealthDispatch({ type: "RESET_DETAILS" })
    if (isBrowser()) mentalHealthDispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    navigate("/complete")
    setLoading(false)
  }

  const handleErrorCallback = () => {
    setLoading(false)
  }

  const handleSubmit = async () => {
    setLoading(true)
    await handleSubmitBooking({
      callback: handleSuccessCallback,
      errorCallback: handleErrorCallback,
      mentalHealthState,
      module: pageContext?.module?.name,
      userData,
      addresses,
    })
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      isPrivate
    >
      <Container isCentered>
        <PersonalDetails userData={userData} addresses={addresses} />
        {sectionSummaryFields.map((section) => {
            return (
              <Section
                title={section?.section}
                addOns={{
                  right: <EditDetailsButton route={section.link} />,
                }}
              >
                <div className="table-container">
                  <table class="table is-fullwidth is-size-5">
                    <tbody>
                      {section.fields
                        .sort((firstField, secondField) => {
                          return firstField.order - secondField.order
                        })
                        .map((field) => {
                          let finalValue = null
                          switch (field.type) {
                            case "select":
                              finalValue = get(mentalHealthState, field.name)
                                ? get(mentalHealthState, field.name)?.label
                                : ""
                              break
                            case "date":
                              finalValue = get(mentalHealthState, field.name)
                                ? `${
                                    get(mentalHealthState, field.name)?.month
                                      ?.label
                                  } ${
                                    get(mentalHealthState, field.name)?.date
                                      ?.label
                                  }, ${
                                    get(mentalHealthState, field.name)?.year.label
                                  }`
                                : ""
                              break
                            case "address":
                              finalValue = get(mentalHealthState, field.name)
                                ? `${
                                    get(mentalHealthState, field.name)
                                      ?.streetAddress
                                  }, ${
                                    get(mentalHealthState, field.name)?.city
                                      ?.label
                                  }, ${
                                    get(mentalHealthState, field.name)?.province
                                      ?.label
                                  }`
                                : ""
                              break
                            case "schedule":
                              finalValue = get(mentalHealthState, field.name)
                                ? `${
                                    get(mentalHealthState, field.name)?.day?.label
                                  }, ${
                                    get(mentalHealthState, field.name)?.time
                                      ?.label
                                  }`
                                : ""
                              break
                            case "checkbox":
                              if (Array.isArray(mentalHealthState[field.name])) {
                                finalValue = mentalHealthState[field.name].join(", ");
                              } else {
                                finalValue = mentalHealthState[field.name];
                              }
                              break
                            default:
                              finalValue = get(mentalHealthState, field.name)
                              break
                          }

                          if (!!finalValue){
                            return (
                              <tr>
                                <td>{field.summaryLabel}</td>
                                <td
                                  className={classNames(
                                    "has-text-weight-bold py-1",
                                    styles["summary__tableData"]
                                  )}
                                >
                                  {finalValue}
                                </td>
                              </tr>
                            )
                          }
                          return null
                        })}
                    </tbody>
                  </table>
                </div>
              </Section>
            )
        })}
        <SummaryConsent
          sectionFormFields={sectionFormFields}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          pageContext={pageContext}
          loading={loading}
        />
      </Container>
    </Layout>
  )
}

export default BookingSummary
